<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input" style="width: 240px;">
          <el-input
            v-model="querifo.keyword"
            size="medium"
            placeholder="输入标题"
          ></el-input>
        </nav>
        <label style="font-size: 14px; float: left; transform: translateY(9px)"
          >发布日期：</label
        >
        <nav class="input" style="width: 350px;">
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            v-model="querifo.createTime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            size="medium"
          >
          </el-date-picker>
        </nav>

        <nav class="input">
          <el-button @click="reset" size="small">重置</el-button>
          <el-button @click="submit" type="primary" size="small">{{
            btn ? '搜索' : 'loading..'
          }}</el-button>
          <el-button
            type="primary"
            @click="new_add"
            size="small"
            v-show="$store.state.btn_if.indexOf('tag_add') != -1"
            >新增专题</el-button
          >
        </nav>
      </nav>
    </el-card>
    <!-- <el-button type="primary" class="btnAdd" @click="editdialogs = true"
      >新增专题</el-button
    > -->

    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column prop="id" label="编号"> </el-table-column>
        <el-table-column prop="name" label="标题"> </el-table-column>
        <el-table-column
          prop="desc"
          label="描述"
          show-overflow-tooltip
          width="250"
        >
        </el-table-column>
        <el-table-column prop="banner" label="图片">
          <template slot-scope="{ row }">
            <img
              :src="baseUrl + yu + row.banner"
              alt=""
              style="width: 150px;"
            />
          </template>
        </el-table-column>
        <el-table-column prop="initViews" label="浏览量"></el-table-column>
        <el-table-column prop="publishStatus" label="发布状态">
          <template slot-scope="{ row }">
            <el-tag type="warning" size="small" class="box">{{
              row.publishStatus ? '发布' : '不发布'
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="top" label="推荐状态">
          <template slot-scope="{ row }">
            <el-tag type="warning" size="small" class="box">{{
              row.top ? '推荐' : '不推荐'
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="oderNum" label="排序">
          <template #header>
            <span>排序</span>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">数值越大，显示越靠前</div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{row}">
              <Remark 
              :row="row" 
              :val="{
                url: '/admin/Tag/editOrderNum',
                pw: 'enterprise_cert_show'
              }"
              @getlist="getlsit">
              </Remark>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          sortable
          width="180"
        >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="showEdit(scope.row.id)"
              class="skyblue"
              v-show="$store.state.btn_if.indexOf('tag_list_edit') != -1"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="removeuserid(scope.row.id)"
              class="red"
              v-show="$store.state.btn_if.indexOf('tag_list_delete') != -1"
              >删除</el-button
            >
            <el-button
              type="text"
              icon="el-icon-share"
              size="mini"
              style="color: tan;"
              @click="setTop(scope.row, scope.row.top == 0 ? 1 : 0)"
              v-show="$store.state.btn_if.indexOf('tag_top') != -1"
              >{{ scope.row.top == 0 ? '推荐' : '不推荐' }}</el-button
            >
            <el-button
              type="text"
              icon="el-icon-s-promotion"
              size="mini"
              class="green"
              @click="setPS(scope.row, scope.row.publishStatus == 0 ? 1 : 0)"
              v-show="$store.state.btn_if.indexOf('tag_publish') != -1"
              >{{ scope.row.publishStatus == 0 ? '发布' : '不发布' }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="querifo.pagenum"
        background
        :page-sizes="[1, 2, 5, 10]"
        :page-size="querifo.pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      :title="editdialog ? '修改专题' : '新增专题'"
      :visible.sync="editdialogs"
      width="50%"
    >
      <el-form ref="editFormRef" label-width="70px" :model="form">
        <el-form-item label="标题" prop="realname">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="realname">
          <el-input v-model="form.desc"></el-input>
        </el-form-item>

        <el-form-item label="图片" prop="banner">
          <Img :banner="form.banner" :action="action" @event="setImg" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            editdialogs = false
            form = {}
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="add" v-show="!editdialog">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
        <el-button type="primary" @click="edituserinfo" v-show="editdialog">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Img from '../../components/upImg/index.vue'
import Remark from '../../components/remark/orderNum.vue'
export default {
  components: { 
      Img,
      Remark,
     },
  data() {
    return {
      querifo: {
        pagenum: 1,
        pagesize: 100,
        keyword: '',
        createTime: []
      },
      action: {
        action: 'article'
      },
      btn: true,

      list: [],
      editform: {
        name: '',
        type: '',
        id: 0
      },
      form: {
        name: '',
        desc: '',
        banner: ''
      },
      total: 0,
      editdialog: false,
      editdialogs: false,
      title: '',
      createtime: '',
      yu: process.env.VUE_APP_STATIC_DIR,
      baseUrl: this.$store.state.env.cos
    }
  },
  methods: {
    setImg(val) {
      this.form.banner = val
    },
    async setPS(row, val) {
      let { data: res } = await this.$http.post('/admin/Tag/publish', {
        id: row.id,
        publishStatus: val
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getlsit()
      } else {
        this.$message.error(res.message)
      }
    },
    async setTop(row, val) {
      let { data: res } = await this.$http.post('/admin/Tag/top', {
        id: row.id,
        top: val
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getlsit()
      } else {
        this.$message.error(res.message)
      }
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    handleSizeChange(e) {
      // console.log(e)
      this.querifo.pagesize = e
      this.getlsit()
    },
    handleCurrentChange(e) {
      // console.log(e)
      this.querifo.pagenum = e
      this.getlsit()
    },
    reset() {
      this.title = ''
      this.createtime = ''
    },
    submit() {
      this.getlsit()
    },
    getlsit() {
      this.$http
        .get('/admin/Tag/getList', {
          params: {
            page: this.querifo.pagenum,
            pageSize: this.querifo.pagesize,
            keyword: this.querifo.keyword,
            createTime: this.querifo.createTime.join('~')
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.list = res.data.list
            this.total = res.data.totalCount
          } else {
            this.$message.error(res.message)
            
          }
        })
    },
    showEdit(id) {
      this.$http.get('/admin/Tag/getById?id=' + id).then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.editdialogs = true
          this.editdialog = true
          this.form = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    new_add() {
      this.editdialogs = true
      this.editdialog = false
      this.form = {
        name: '',
        desc: '',
        banner: ''
      }
    },
    edituserinfo() {
      if (this.btn) {
        this.$http.post('/admin/Tag/edit', this.form).then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.editdialogs = false
            this.getlsit()
          } else {
            this.$message.error(res.message)
          }
          this.btn = true
        })
      }
      this.btn = false
    },
    removeuserid(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/Tag/del', { params: { id: id } })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                if (this.total % 10 == 1) {
                  this.querifo.pagenum--
                }
                this.getlsit()
              } else {
                this.$message.error(res.message)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    add() {
      if (this.btn) {
        this.$http.post('/admin/Tag/add', this.form).then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            location.reload()
          } else {
            this.$message.error(res.message)
          }
          this.btn = true
        })
      }
      this.btn = false
    }
  },
  created() {
    this.getlsit()
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;

  .input {
    width: 37%;
    display: inline-block;
  }
}
.red {
  color: red;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
</style>
